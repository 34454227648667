export enum MouseMode {
  NONE,
  SELECTING,
  HOVER,
  MOVING,
  ADD_NODE,
  ADD_ELEMENT,
  ADD_DIMLINE,
  PASTE_CLIPBOARD,
}
